/* You can add global styles to this file, and also import other style files */
@import 'primeicons/primeicons.css';

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

// Don't allow user select
* {
  user-select: none;
}

label,
i, p {
  font-size: 12px;
  color: var(--p-text-color);
}

br {
  caret-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: lightgray;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

gridster {
  background-image: url("/assets/images/Perifor-Background.png") !important;
}

gridster>gridster-item {
  background: var(--p-content-background);
}

.workspace-cmp-header div {
  font-weight: 150;
  font-size: small;
  padding-left: 3px;
}

.p-inputnumber-input, .p-inputtext {
  text-align: left;
  height: 22px;
  width: 100%;
  font-size: 12px;
}

.p-inputgroupaddon {
  height: 22px;
  font-size: 12px;
}

.p-listbox-list-container ul li {
  padding: 7px;
  font-size: 11px;
}

.p-orderlist-controls {
  display: none;
}

.p-inputnumber.ng-dirty.ng-invalid>.p-inputtext {
  border-color: #ef5350;
}

.p-button-icon-only.p-button-rounded {
  height: 1.25rem;
}

.outlined-button {
  display: flex;
  height: 22px;

  button span {  
    font-weight: 150;
    font-size: small;
  }
}

.p-tooltip .tooltip {
  min-width: 375px;
}

.p-tooltip .p-tooltip-text {
  font-size: 11px;
}

.p-toolbar {
  border-radius: 0px;
}

.p-inputgroup p-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-selectbutton .p-button {
  margin-left: 0;
  margin-right: 0;
}

// The panel mods here are what enable vertical scrolling
.p-panel {
  height: calc(100% - 24px);
  border: none;
}

.p-panel-header {
  padding: 0px;
}

.p-panel-content-container {
  height: 100%;
}

.p-panel-content {
  height: 100%;
  display: block;
}
// End Panel

.p-button-icon-only {
  width: unset;
}

// Inputs
.p-fieldset-legend {
  padding: 0.2rem;

  .p-fieldset-legend-label {
    font-weight: 500;
    font-size: small;
  }
}

.p-select, .p-multiselect, .p-treeselect {
  height: 22px;
  font-size: 12px;

  span {
    margin-left: 2px;
  }
}

.p-select-overlay * {
  font-size: 12px;
  padding: 2px;
}

// Table
thead th {
  font-weight: 500;
  font-size: 13px;
}

//  -- Component Specific Styles (Avoids Ng-Deep)
// To Target specific components use root .css name first --//

// -- Well Explorer -- //
.well-explorer-cmp {
  .p-treetable-scrollable-body{
    height: 100vh;
    overflow-y: auto;
  }
}

.p-datatable {
  .p-datatable-thead>tr>th {
    // border: 2px solid;
    padding: .5em;
    text-wrap: auto;
    text-align: center;
    height: 5px;
  }

  .p-datatable-tbody>tr>td {
    // display: table-cell;
    // border: 1px solid;
    font-size: small;
    font-weight: 150;
    padding-top: .1em;
    padding-bottom: .1em;
    padding-right: 0px;
  }
}

.sidebar-cmp {
  .p-dock {
    text-align: center;
    position: unset;
  }

  .p-dock-item-link {
    height: unset;
  }
}

// Global Overrides of Prime NG Components
.p-card-body {
  padding: 0;
}

.p-tree {
  padding: 0;
}

.p-tree-node-content {
  padding: 0;
}

.highlight-column-header, .highlight-column {
  background-color: rgba(201, 201, 187, 0.2);
}

.p-selectbutton {
  height: 22px;
}

.p-selectbutton .p-togglebutton-label {
  font-size: small !important;
}

.toolbar-menu {
  padding: 5px;
}

.highlight {
  color: rgb(118, 216, 192);
  transition: color 0.5s ease-in-out;
}

.p-splitbutton {
  height: 18px;
}

.units-convert-dialog .p-dialog-header {
  padding: 12px;
}

.units-convert-dialog .p-dialog-content {
  padding: 12px;
}

.validation-error {
  color: #ff0000;
  font-size: 11.5px;

  i {
    color: #ff0000;
  }
}

.level-icon {
  height: 18px;
  width: 18px;
  padding-right: 5px;
  filter: invert(65%) sepia(39%) saturate(508%) hue-rotate(73deg) brightness(94%) contrast(87%);
}

.p-tab {
  font-size: small;
}

.multiSelectClassNoCheckbox .p-checkbox {
  display: none !important;
}
